.skeleton-loader {
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 1.25rem;
    background-color: var(--white-100);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .skeleton-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: space-between;
        .skeleton-text {
            height: 1.5rem;
            width: 11.25rem;
            background-color: var(--black-30);
            opacity: 0.3;
            border-radius: 4px;
            background: linear-gradient(to right, var(--white-100) 8%, var(--black-30) 18%, var(--white-100) 33%);
            background-size: 1000px 104px;
            animation: shimmer 1.5s infinite linear;
        }
    }
    .skeleton-image {
        width: 100px;
        height: 100px;
        aspect-ratio: 1/1;
        background-color: var(--black-30);
        opacity: 0.3;
        background: linear-gradient(to right, var(--white-100) 8%, var(--black-30) 18%, var(--white-100) 33%);
        background-size: 1000px 104px;
        animation: shimmer 1.5s infinite linear;
        border-radius: 10px;
    }
    @media screen and (max-width: 768px) {
        .skeleton-content {
            gap: 1rem;
            .skeleton-text {
                width: 10rem;
            }
        }
        .skeleton-image {
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .skeleton-loader {
        gap: 1.25rem;
    }
}

.skeleton-loader.hotel {
    border: 1px solid var(--black-30);
    height: 125px;
    width: 125px;
    border-radius: 50%;
    pointer-events: none;
    opacity: 0.3;
    background: linear-gradient(to right, var(--white-100) 8%, var(--black-15) 18%, var(--white-100) 33%);
    background-size: 1000px 104px;
    animation: shimmer 1.5s infinite linear;
    border-radius: 50%;
    align-items: center;
    .skeleton-content {
        display: none;
    }
}

.canvas-wrapper-skeleton {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
    span {
        height: 1.5rem;
        width: 10vw;
        background-color: var(--black-30);
        opacity: 0.3;
        border-radius: 4px;
        background: linear-gradient(to right, var(--white-100) 8%, var(--black-30) 18%, var(--white-100) 33%);
        background-size: 1000px 104px;
        animation: shimmer 1.5s infinite linear;
    }
    .skeleton-doughnut {
        box-sizing: border-box;
        display: block;
        height: 100px;
        width: 100px;
        background-color: var(--black-30);
        border-radius: 50%;
        opacity: 0.3;
        background: linear-gradient(to right, var(--white-100) 8%, var(--black-15) 18%, var(--white-100) 33%);
        background-size: 1000px 104px;
        animation: shimmer 1.5s infinite linear;
    }
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
