.main-router-content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  
  &.fade-in {
    opacity: 1;
  }
  
  &.fade-out {
    opacity: 0;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: var(--black-60);
  font-size: 1rem;
  text-align: center;
  background-color: var(--white-100);
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 2rem;
  margin-top: 1rem;
}